/* eslint-disable import/prefer-default-export */
export const MENU_ITEMS = [
  {
    name: "main",
    alternative: "01101101 01100001 01101001 01101110",
    root: true,
  },
  {
    name: "about",
    alternative: "01101101 01100101",
  },
  {
    name: "music",
    alternative: "01100001 01110010 01110100",
  },
];
